.howtobuy-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(to bottom, #d9d9d9, rgba(255, 192, 203, 0.315));
    border-bottom: solid 1px black;
    border-radius: 10%;
    height: 100vh;
  }
  
  .text-container6 {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 50%;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-bottom: 0;
   
  }
  
  .text-container6 h2 {
    font-family: 'Pattanakarn Medium', sans-serif;
    line-height: 75px;
    font-size: 3rem;
    color: black;
    background-color: #5af7c3;
    border-radius: 50px;
    text-align: center;
    width: 50%;
    padding-bottom: 0;
  }

  .descrip6 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    text-align: left;
   
  }

  .descrip6 h3,
  .descrip6 p {
    margin: 0;
    padding: 0;
    padding-bottom: 1%;
  }
  
  
  .descrip6 h3 {
    font-family: 'Arial Narrow', sans-serif;
    font-size: 1.5rem;
    color: #000;
    line-height: 100%;
  }
  

  .descrip6 p {
    font-size: 1rem;
    font-family: 'Arial Narrow', sans-serif;
    color: #000;
  }
  
  @media (max-width: 1366px) {
  
    .text-container6 h3 {
      font-size: 1.2rem;
      color: #000;
      text-indent: 4rem;
    }
  
    .descrip6 em {
      font-size: 2rem;
    }
  
    .text-container6 h1 {
      font-size: rem;
      line-height: 60px;
    }
  
    .text-container6 h2 {
      font-size: 2.5rem;
      line-height: 60px;
      width: 70%;
    }
  }
  
  
  @media (max-width: 1200px) {
    
    .text-container6 h1 {
      font-size: 4rem;
      line-height: 60px;
    }
  
    .text-container6 h2 {
      font-size: 2.5rem;
      line-height: 60px;
      width: 70%;
    }
  
    .text-container6 h3 {
      font-size: 1.3rem;
      text-indent: 4rem;
    }
  
    .descrip6 em {
      font-size: 2rem;
    }
  
  }
  
  @media (max-width: 800px) {
    .howtobuy-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 10%;
      height: auto;  
    }
  
    .image-container6 {
      width: 100%;
    }
  
    .text-container6 {
      align-items: center;
      text-align: center;
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .text-container6 h2 {
      width: 60%;
      text-align: center;
    }
  
    .text-container6 h3 {
      line-height: 120%;
      text-indent: 0;
      margin: 0;
    
    }
  
    .descrip6 {
      text-align: left;
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .howtobuy-image {
      width: 80%;
      height: auto;
    }
  }
  
  @media (max-width: 600px) {
   
    .text-container6 h1 {
      font-size: 3rem;
      line-height: 50px;
    }
  
    .text-container6 h2 {
      font-size: 2rem;
      line-height: 50px;
    }
  
    .text-container6 h3 {
      font-size: 1rem;
      text-indent: 0;
    }
  
    .descrip6 em {
      font-size: 1.5rem;
    }
  
    .howtobuy-image {
      max-width: 500px;
      height: auto;
    }
  }
  
  @media (max-width: 400px) {
  
  
    .text-container6 h1 {
      font-size: 2.5rem;
      line-height: 40px;
    }
  
    .text-container6 h2 {
      font-size: 1.5rem;
      line-height: 40px;
    }
  
    .text-container6 h3 {
      font-size: 1rem;
      text-indent: 0;
    }
  
    .howtobuy-image {
      max-width: 250px;
    }
  }
  