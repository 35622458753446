.tokenomics-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #d9d9d9, rgba(255, 192, 203, 0.315));
    height: 100vh;
    border-bottom: solid 1px black;
    border-radius: 10%;
  }
  
  .image-container5 {
    padding-top: 9%;
    width: 50%;
  }
  
  .text-container5 {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 60%;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    padding-right: 10%;
  }
  
  .text-container5 h2 {
    font-family: 'Pattanakarn Medium', sans-serif;
    line-height: 75px;
    font-size: 3rem;
    color: black;
    background-color: #5af7c3;
    border-radius: 50px;
    text-align: center;
    width: 50%;
  }
  
  .text-container5 h3 {
    font-family: 'Arial Narrow', sans-serif;
    font-size: 2rem;
    color: #000;;
  }
  .text-container5 p {
    font-family: 'Arial Narrow', sans-serif;
    font-size: 1.2rem;
    color: #000;
  }

  .text-container5 h3,
  .text-container5 p {
    margin: 0;
    padding-top: 0;
    padding-bottom: 1%;
  }
  
  .descrip4 {
    text-align:left;
  }
  .descrip5 {
    line-height: 20px;
    text-align:left;
  }
  
  .tokenomics-image {
    width: 60%;
    height: auto;
    padding-left: 20%;
    aspect-ratio: 1 / 1;
  }
  .indented2{
    padding-left: 50px;
  }
  
  @media (max-width: 1200px) {
  
    .text-container5 h2 {
      font-size: 2.5rem;
      line-height: 60px;
      width: 70%;
    }
  
    .text-container5 h3 {
      font-size: 1.8rem;
      text-indent: 4rem;
    }

  
  }
  
  @media (max-width: 800px) {
    .tokenomics-container {
      flex-direction: column-reverse;
      overflow: hidden;
      width: 100%;
      height: auto;
      align-items: center;
      text-align: center;

    }
  
    .image-container5 {
      width: 100%;
    }
  
    .text-container5 {
      align-items: center;
      text-align: center;
      width: 100%;
      padding-left: 10%;
    }
  
    .text-container5 h2 {
      width: 100%;
      text-align:center;
    }
  
    .text-container5 h3 {
      text-indent: 0;
      margin: 0;
    }
    .tokenomics-image {
      padding-left: 0;
    }
  
    .descrip4 {
      text-align: left;
      padding-right: 5%;
      padding-left: 5%;
    }
    .descrip4 p {
      text-align: left;
    }
  
    .tokenomics-image {
      width: 80%;
      height: auto;
    }
  }
  
  @media (max-width: 600px) {
    .tokenomics-container {
      width: 100%;
    }
  
    .text-container5 h2 {
      font-size: 2rem;
      line-height: 50px;
    }
  
    .text-container5 h3 {
      font-size: 1rem;
      text-indent: 0;
    }
  
  
    .tokenomics-image {
      max-width: 500px;
      height: auto;
    }
  }
  
  @media (max-width: 400px) {
    .tokenomics-container {
      width: 100%;
      padding-top: 5%;
    }

    .text-container5 h2 {
      font-size: 1.5rem;
      line-height: 40px;
    }
  
    
    .text-container5 p {
      font-size: 1rem;
    }
  
    .tokenomics-image {
      max-width: 250px;
    }
  }
  