
html, body {
  margin: 0;
  padding: 0;
}
.navbar {
  width: 100%;
  position: fixed;
  margin: 0;
  z-index: 2;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo img{
  max-width: 100%;
  height: auto;
  aspect-ratio: 3 / 1;
}


.menu-icon {
  display: none;
  cursor: pointer;
  margin-left: 20px;
}

.menu-icon div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px 0;
  transition: 0.4s;
}

.nav-menu {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-right: 10%;
}

.nav-menu li {
  margin: 0 10px;
}

.nav-menu li a {
  font-family: 'Pattanakarn Medium';
  color: black;
  text-align: center;
  padding: 8px 10px;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
}

.nav-menu li a:hover {
  border-radius: 10%;
  background-color: #5af7c3;
  cursor: pointer;
}

.dropdown {
  position: relative;
}

.dropdown span {
  font-family: 'Pattanakarn Medium';
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown span::after {
  content: '';
  margin-left: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  background-color: #d9d9d9;
  right: 50%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 300px;
  z-index: 1;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu li a {
  font-family: 'Pattanakarn Medium';
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
}

.dropdown-active {
  display: block;
}
/* Styles for full screen to 801px */
@media (max-width: 1200px) and (min-width: 801px) {
  .logo {
    min-width: 100%;
    margin-left: 25%;
   }
   
  .nav-menu li a {
    font-size: 1.2rem;
    padding: 6px 8px; 
  }

  .nav-menu li {
    margin: 0 8px; /* Decrease margin */
  }

  .nav-menu {
    padding-right: 5%; /* Decrease padding on the right */
  }
}


@media (max-width: 1200px) {
  
  .logo {
    width: 30%;
    height: auto;
  }

  .nav-menu li a {
    font-size: 1.5rem;
  }
}

@media (max-width: 800px) {
  .navbar {
    padding-top: 10px;
    display: block;;
  }

  .nav-container {
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    width: 30%;
    margin-right: 10px;
    padding-left: 5%;
  }

  .menu-icon {
    display: block;
    padding-right: 5%;
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    background-color: #d9d9d9;
  }

  .nav-menu-active {
    height: 100vh;
  }


  .nav-menu li a {
    font-size: 1.5rem;
  }

  .menu-icon.open .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-icon.open .bar2 {
    opacity: 0;
  }

  .menu-icon.open .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
  }
}
