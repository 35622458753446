.roadmap-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #d9d9d9, rgba(255, 192, 203, 0.315));
  height: 100vh;
  border-bottom: solid 1px black;
  border-radius: 10%;

}

.image-container2 {
  width: 50%;
  padding-right: 50px;
}

.text-container2 {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 10%;
}
.roadmapTitle{
  display: flex;
  justify-content: center;
}
.parag{
  margin-top: 10px;
  margin-bottom: 5px; 
  line-height: 100%;
}

.reminder{
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-size: 'Arial Narrow', sans-serif;
}

.text-container2 h2 {
  font-family: 'Pattanakarn Medium', sans-serif;
  line-height: 75px;
  font-size: 3rem;
  color: black;
  background-color: #5af7c3;
  margin: 10px 0;
  border-radius: 50px;
  text-align: center;
  width: 50%;
}

.text-container2 h3 {
  font-family: 'Pattanakarn Medium', sans-serif;
  line-height: 200%;
  font-size: 2rem;
  color: black;
  background: linear-gradient(to right, #5af7c3, #ffd90000);
  margin: 0;
  border-radius: 50px;
  text-align: center;
  text-indent: 50px;
  width: 100%;
}

.descrip {
  font-family: 'Arial Narrorw', sans-serif;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.descrip h3 {
  width: 60%;
}

.descrip p {
  font-size: 1.5rem;
  width: 60%;
}
h4{
  line-height: 100%;
}

.road-map {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
}

@media (max-width: 1366px) {
  .roadmap-container {
    padding-top: 5%;
  }
}

@media (max-width: 1200px) {
  .roadmap-container {
    width: 100%;
  }

  .text-container2 h3, .text-container2 h2 {
    font-size: 1.5rem;
  }

  .descrip {
    font-size: 1.8rem;
  }
}

@media (max-width: 800px) {

  
  .roadmap-container {
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
  }

  .image-container2 {
    width: 100%;
    padding: 0;
  }

  .road-map {
    width: 80%;
    height: auto;
    padding-left: 10%;
  }

  .text-container2 {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .roadmapTitle{
    width: 100%;
    text-align: center;
  }

  .text-container2 h2 {
    width: 80%;
  }

  .text-container2 h3 {
    font-size: 1rem;
    line-height: 150%;
    text-indent: 0;
    padding: 0;
  }

  .descrip {
    flex-direction: column;
    align-items: center;
  }
  .descrip p{
   font-size: 1rem;
  }

  .descrip h3, .descrip p {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 600px) {

  .roadmap-container {
    width: 100%;
    padding-top: 10%;
  }

}

@media (max-width: 400px) {
  .roadmap-container {
    width: 100%;
  }
}
