.footer {
  background: linear-gradient(to bottom, #d9d9d9, #030303);
  color: #fff;
  height: auto;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  z-index: -1000;
  font-family: 'Arial Narrow', sans-serif;
}

.footer .footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Centers the sections horizontally */
  padding: 20px 10px;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  padding: 10px;
  min-width: 300px; 
  text-align: center; /* Centers the content within each section */
}

.footer-section h1 {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.footer .footer-content .footer-section .logo-text {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

.footer .socials {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 20px;
}

.footer .socials a {
  color: #fff;
  font-size: 24px; /* Ensure icons are big enough */
}

.footer .footer-bottom {
  text-align: center;
  padding: 10px 0;
  background-color: #222;
  width: 100%;
}

@media (max-width: 760px) {
  .footer .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer .footer-content .footer-section {
    text-align: center;
    padding: 10px 20px;
  }

  .footer .socials {
    justify-content: center;
  }
}
