body {
  background-color: #d9d9d9;
}
.owlbert-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: linear-gradient(to bottom, #d9d9d9, rgba(255, 192, 203, 0.315));
  height: 100vh;
  border-bottom: solid 1px black;
  border-radius: 10%;
}

.image-container {
  width: 50%;
  position: relative;
  padding-right: 5%;
}
.image-containerDL {
  width: 100%;
  position: relative;
  display: inline-block;
}

.text-container {
  text-align: left;
  width: 50%;
  padding-left: 10%;
}

.text-container h1 {
  font-family: 'Pattanakarn Medium', sans-serif;
  font-size: 6rem;
  color: #000;
  margin: 0;
  line-height: 1.2;
}

.text-container h2 {
  font-family: 'Pattanakarn Medium', sans-serif;
  font-size: 3rem;
  color: black;
  background-color: #5af7c3;
  margin: 10px 0;
  line-height: 1.2;
}

.text-container h2 a {
  font-size: 2rem;
  color: rgb(1, 43, 36);
  border-radius: 20%;
  margin-bottom: 10%;
}

.text-container h3 {
  font-family: 'Pattanakarn Medium', sans-serif;
  font-size: 3rem;
  color: #000;
  line-height: 1.2;
}

.warning {
  color: red;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-icons i {
  font-size: 3rem;
  color: black;
  cursor: pointer;
}

.owlbert-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
}

.clickable-area {
  position: absolute;
  top: 75%; 
  left: 45%;
  width: 20%; 
  height: 5%; 
  cursor: pointer;
}

.clickable-area img{
  width: 60%;
  height: auto;
  aspect-ratio: 1200 / 630;
}

.clickable-area img:hover{
  width: 70%;
}

.wpButton {
  font-family: 'Pattanakarn Medium', sans-serif;
  font-size: 1.5rem;
  position: absolute;
  top: 600px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.wpButton:hover {
  font-size: 1.55rem;
  color: #1100ff;
}


@media (max-width: 1200px) {
  .owlbert-container {
    align-items: center;
  }
  .text-container h1 {
    font-size: 5rem;
  }

  .text-container h2 {
    font-size: 3.5rem;
  }
  .text-container h2 a {
    font-size: 3.5rem;
  }

  .text-container h3 {
    font-size: 2.5rem;
  }

  .social-icons i {
    font-size: 2.5rem;
  }

  .wpButton {
    top: 230px;
    left: 50%;
  }
}

@media (max-width: 800px) {

  .owlbert-container {
    flex-direction: column;
    padding-top: 15%;
    width: 100%;
    height: auto;
  }

  .image-container {
    width: 100%;
    padding: 0;
  }

  .owlbert-image {
    max-width: 80%;
    height: auto;
    padding-left: 10%;
    aspect-ratio: 1 / 1;
  }

  .text-container {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .text-container h1 {
    font-size: 4rem;
  }

  .text-container h2 {
    font-size: 3rem;
  }
  .text-container h2 a {
    font-size: 3rem;
  }

  .text-container h3 {
    font-size: 2rem;
  }

  .social-icons {
    justify-content: center;
  }

  .social-icons i {
    font-size: 2rem;
  }

  .wpButton {
    font-size: 1.4rem;
    top: 500px;
  }

  .wpButton:hover {
    font-size: 1.45rem;
  }
}

@media (max-width: 600px) {

  h4{
    font-size: .8rem;
  }

  .owlbert-container {
    width: 100%;
  }
  .text-container h1 {
    font-size: 3rem;
  }

  .text-container h2 {
    font-size: 2.5rem;
  }
  .text-container h2 a {
    font-size: 2.5rem;
  }

  .text-container h3 {
    font-size: 1.5rem;
  }

  .social-icons i {
    font-size: 1.5rem;
  }

  .wpButton {
    font-size: 1.2rem;
    top: 260px;
  }

  .wpButton:hover {
    font-size: 1.25rem;
  }
}

@media (max-width: 400px) {
  .owlbert-container {
    width: 100%;
    padding-top: 30%;
  }
  
  .text-container h1 {
    font-size: 2rem;
  }

  .text-container h2 {
    font-size: 2rem;
  }
  .text-container h2 a {
    font-size: 2rem;
  }
  

  .text-container h3 {
    font-size: 1rem;
  }

  .social-icons i {
    font-size: 1rem;
  }

  .wpButton {
    font-size: 1rem;
    top: 300px;
  }

  .wpButton:hover {
    font-size: 1.05rem;
  }
}