.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #d9d9d9, rgba(255, 192, 203, 0.315));
  height: 100vh;
  border-bottom: 1px solid black;
  border-radius: 10%;
}

.image-container3 {
  width: 50%;
}

.text-container3 {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding-right: 10%;
}

.text-container3 h2 {
  font-family: 'Pattanakarn Medium', sans-serif;
  line-height: 75px;
  font-size: 3rem;
  color: black;
  background-color: #5af7c3;
  border-radius: 50px;
  text-align: center;
  width: 35%;
}

.descrip2 {
  font-size: 1.5rem;
  line-height: 40px;
  text-align: left;
}

.descrip2 em {
  font-size: 2rem;
  font-weight: bold;
}

.about-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1; /* Maintain the correct aspect ratio */
}

@media (max-width: 1366px) {
  .text-container3 h2 {
    font-size: 2.5rem;
    line-height: 60px;
    width: 70%;
  }
}

@media (max-width: 1200px) {
  .text-container3 h2 {
    font-size: 2.5rem;
    line-height: 60px;
    width: 70%;
  }

  .text-container3 h3 {
    font-size: 1.3rem;
    text-indent: 4rem;
  }

  .descrip2 em {
    font-size: 2rem;
  }
}

@media (max-width: 800px) {
  .about-container {
    flex-direction: column-reverse;
    padding-top: 5%;
    height: auto;
  }

  .image-container3 {
    width: 100%;
  }

  .text-container3 {
    text-align: center;
    width: 100%;
    padding-left: 10%;
  }

  .text-container3 h2 {
    width: 35%;
  }

  .descrip2 {
    text-align: center;
    padding: 0 5%;
  }

  .about-image {
    width: 80%;
    padding-left: 10%;
  }
}

@media (max-width: 600px) {
  .text-container3 h2 {
    font-size: 2rem;
  }

  .descrip2 {
    line-height: 100%;
  }

  .descrip2 em {
    font-size: 1.2rem;
  }

  .descrip2 p {
    font-size: 1rem;
  }

  .about-image {
    max-width: 500px;
  }
}

@media (max-width: 400px) {
  .about-container {
    padding-top: 20%;
  }

  .text-container3 h2 {
    font-size: 1.5rem;
    line-height: 40px;
  }

  .descrip2 p{
    font-size: 01rem;
    line-height: 150%;
  }

  .descrip2 em {
    font-size: 1.2rem;
  }

  .about-image {
    max-width: 250px;
    padding-left: 17%;
  }
}
