.team-container {
  padding-top: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #d9d9d9, rgba(255, 192, 203, 0.315));
  border-bottom: solid 1px black;
  border-radius: 10%;
  height: 100vh;
}

.text-container4 h2 {
  font-family: 'Pattanakarn Medium', sans-serif;
  line-height: 75px;
  font-size: 3rem;
  color: black;
  background-color: #5af7c3;
  margin: 10px 0;
  border-radius: 50px;
  text-align: center;
  width: 250px;
}

.image-container4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.image-container4 img {
  width: 30%;
  max-width: 300px;
  aspect-ratio: 1 / 1;
}

.text-container4 {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
}

.descrip3 p {
  font-family: 'Arial Narrow', sans-serif;
  font-size: 1.5rem;
  color: #000;
  margin-top: 20px;
  text-indent: 0;
}

.descrip3 {
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.descrip3second {
  text-align: left;
  line-height: 10%;
  font-family: 'Arial Narrow', sans-serif;
}

.indented {
  padding-left: 50px;
}

@media (max-width: 1366px) {
  .team-container {
    padding-top: 5%;
  }
 
}

@media (max-width: 1200px) {
  .text-container4 {
    padding-top: 25%;
  }
  
  .text-container4 h2 {
    font-size: 2.5rem;
    width: 200px;
  }

  .descrip3 {
    line-height: 35px;
  }
}

@media (max-width: 800px) {
  .team-container {
    padding-top: 0;
    width: 100%;
  }
  .text-container4 {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
  }
  .text-container4 h2 {
    font-size: 2rem;
    width: 150px;
  }

  .image-container4 img {
    width: 40%;
    max-width: 200px;
  }

  .descrip3 {
    line-height: 30px;
  }
}

@media (max-width: 600px) {
  .team-container {
    width: 100%;
  }
  .text-container4 h2 {
    font-size: 1.8rem;
    width: 130px;
  }

  .image-container4 img {
    width: 50%;
    max-width: 150px;
  }

  .descrip3 {
    line-height: 20px;
  }
  .descrip3 p {
    font-size: 1rem;
  }
  .descrip3second {
    line-height: 105%;
  }
}

@media (max-width: 400px) {

  .team-container {
    padding-top: 10%;
    padding-left: 0;
    padding-right: 0%;
    width: 100%;
    height: auto;
  }

  .text-container4 h2 {
    font-size: 1.5rem;
    width: 100px;
  }

  .image-container4 img {
    width: 60%;
    max-width: 120px;
  }
}
